import React, { useState, useEffect } from 'react';
/** @jsxRuntime classic /
/* @jsx jsx */
import { jsx, css } from "@emotion/core";
import {Row, Col, DatePicker, Select, Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import sunny from './img/sunny.png';
import cloudy from './img/cloudy.png';
import rainy from './img/rainy.png';
import fair from './img/rainy.png';
import m_c_night_rain from './img/m-c-night-rain.png';
import m_c_rain from './img/m-c-rain.png';
import hazy from './img/hazy.png';
import partly_cloudy from './img/partly-cloudy.png';
import m_cloudy_night from './img/m-cloudy-night.png';
import snow from './img/snow.png';
import './App.css';
const dateFormat = 'DD.MM.YYYY';
const { RangePicker } = DatePicker;
const { Option } = Select;

export interface WorkDoc{
  dt_begin:any;
  dt_end:any;
}

export interface City{
  key:number;
  name:string;
}

export interface Weather{
  id:number;
  dt:string;
  type:any;
  low:number;
  high:number;
}

export interface Event{
  id:number;
  dt:string;
  type:string;
  name:string;
  place:string;
  addres:string;
  cost:number;
}

const columns: ColumnsType<Event> = [
  {
    title: 'Дата',
    dataIndex: 'dt',
    key: 'dt',
  },
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    //render: text => <a>{text}</a>,
  },
  {
    title: 'Тип',
    dataIndex: 'type',
    key: 'type',
    //render: text => <a>{text}</a>,
  },
  {
    title: 'Место',
    dataIndex: 'place',
    key: 'place',
    //render: text => <a>{text}</a>,
  },
  {
    title: 'Адрес',
    dataIndex: 'addres',
    key: 'addres',
  },
  {
    title: 'Стоимость',
    dataIndex: 'cost',
    key: 'cost',
  },
];
function App() {
  const [d,setD]=useState<WorkDoc>({dt_begin:moment(new Date()).hour(0).minute(0).second(0).millisecond(0),dt_end:moment(new Date()).hour(0).minute(0).second(0).millisecond(0)});
  const [d_begin,setBegin]=useState<Date>();
  const [d_end,setEnd]=useState<Date>();
  const [cities,setCities]= useState<City[]>([{key:1,name:"Киров"},{key:2,name:"Санкт-Петербург"}]);
  const [weathers]=useState<Weather[]>([
    {	id:1	,dt:	"10.10.2022"	,type:	fair	,	low: 7, high: 10	},
    {	id:1	,dt:	"11.10.2022"	,type:	m_c_night_rain	,	low: 6, high: 11	},
    {	id:1	,dt:	"12.10.2022"	,type:	m_c_rain	,	low: 5, high: 7	},
    {	id:1	,dt:	"13.10.2022"	,type:	hazy	,	low: 9, high: 13	},
    {	id:1	,dt:	"14.10.2022"	,type:	m_c_rain	,	low: 7, high: 10	},
    {	id:1	,dt:	"15.10.2022"	,type:	partly_cloudy	,	low: 8, high: 14	},
    {	id:1	,dt:	"16.10.2022"	,type:	partly_cloudy	,	low: 9, high: 15	},
    {	id:1	,dt:	"17.10.2022"	,type:	partly_cloudy	,	low: 10, high: 15	},
    {	id:2	,dt:	"10.10.2022"	,type:	partly_cloudy	,	low: 7, high: 8	},
    {	id:2	,dt:	"11.10.2022"	,type:	m_c_rain	,	low: 4, high: 7	},
    {	id:2	,dt:	"12.10.2022"	,type:	m_c_rain	,	low: 7, high: 10	},
    {	id:2	,dt:	"13.10.2022"	,type:	m_c_rain	,	low: 8, high: 11	},
    {	id:2	,dt:	"14.10.2022"	,type:	rainy	,	low: 6, high: 9	},
    {	id:2	,dt:	"15.10.2022"	,type:	rainy	,	low: 6, high: 10	},
    {	id:2	,dt:	"16.10.2022"	,type:	m_c_night_rain	,	low: 7, high: 11	},
    {	id:2	,dt:	"17.10.2022"	,type:	m_cloudy_night	,	low: 3, high: 7	}
      ]);
  const [events]=useState<Event[]>(
    [
      {id:1,dt:	"11.10.2022 19:00"	,type:	"концерт"	,name:	"'Романс': Олег Погудин"	,place:	"Вятская Филармония"	,addres:	"Ленина, 102б"	,cost:	1500.00},
      {id:1,dt:	"13.10.2022 11:00"	,type:	"спектакль"	,name:	"Ладушки"	,place:	"Кировский театр кукол им. Афанасьева"	,addres:	"Спасская, 22"	,cost:	300.00},
      {id:1,dt:	"15.10.2022 20:00"	,type:	"концерт"	,name:	"Гуф"	,place:	"Gaudi"	,addres:	"Володарского, 103а"	,cost:	2000.00},
      {id:1,dt:	"16.10.2022 19:00"	,type:	"спектакль"	,name:	"Денис Дорохов. StandUP"	,place:	"ДК 'Родина'"	,addres:	"Гайдара, 3"	,cost:	1000.00},
      {id:2,dt:	"10.10.2022 19:00"	,type:	"концерт"	,name:	"Открытый микрофон"	,place:	"Руки вверх"	,addres:	"наб. канала Грибоедова, 28"	,cost:	200.00},
      {id:2,dt:	"14.10.2022 21:00"	,type:	"концерт"	,name:	"Dark Jazz"	,place:	"Планетарий 1"	,addres:	"наб. Обводного канала, 74, литера Ц"	,cost:	900.00},
      {id:2,dt:	"14.10.2022 19:00"	,type:	"спектакль"	,name:	"Семья Сориано, или Итальянская комедия"	,place:	"Молодежный театр на Фонтанке"	,addres:	"наб. реки Фонтанки, 114"	,cost:	700.00},
      {id:2,dt:	"16.10.2022 11:00"	,type:	"выставка"	,name:	"Михаил Врубель. К 165-летию со дня рождения"	,place:	"Русский Музей"	,addres:	"Инженерная, 4"	,cost:	150.00}
  ]);

  const[citykey,setCityKey]=useState<number>(0);

  useEffect(() => {
    const doIt = async () => {
      //console.log("citykey:" + citykey + " dt_begin:" + new Date(d.dt_begin)+" dt_end:"+new Date(d.dt_end));
      };
    doIt();
    return function cleanup() {
    };
}, [d, citykey, d_begin,d_end]);

  const sset=(a:any)=>{
    //console.log(a);
    d.dt_begin=a[0].hour(0).minute(0).second(0).millisecond(0);
    d.dt_end = a[1].hour(23).minute(59).second(59).millisecond(999);
    setD(d);
    setBegin(d.dt_begin);
    setEnd(d.dt_end);
  }

  const data=()=>{
    var r:Event[]=[];
    events.map((item)=>(item.id.toString() === citykey.toString() && moment(item.dt,dateFormat)>=d.dt_begin && moment(item.dt, dateFormat) < d.dt_end && r.push(item)));
    return r;
  }
  return (
    <div className="App">
      <Row justify="center">
        <Col></Col>
        <Col>
        <Row gutter={8} justify="center">
          <Col className="gutter-row">
          <p>Даты поездки</p>
        {/**
        <DatePicker css={css` width:10em;`} locale={locale} defaultValue={moment(new Date(d.dt),dateFormat)} format={dateFormat} onChange={(date,dateString)=>setDT(date)}/>
        <DatePicker css={css` width:10em; `} locale={locale} value={moment(new Date(d.dtout),dateFormat)} defaultValue={moment(new Date(d.dtout),dateFormat)} format={dateFormat} disabledDate={disabledDate} onChange={(date,dateString)=>setDTOUT(date)}/>
 */}
            <RangePicker  locale={locale} format={dateFormat} onChange={(e)=>sset(e)}/>
          </Col>
          <Col className="gutter-row">
            <p>Выбор города</p>
            <Select
              showSearch 
              style={{ width: 200 }}
              placeholder="Выберите город"
              optionFilterProp="children"
              filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
              filterSort={(optionA, optionB) =>
                (optionA!.children as unknown as string)
                  .toLowerCase()
                  .localeCompare((optionB!.children as unknown as string).toLowerCase())
              }
              onChange={(e)=>setCityKey(e)}
            >
              {cities.map(item => (
                <Option key={item.key}>{item.name}</Option>
              ))}
            </Select>
          </Col>
        </Row>
        </Col>
        <Col></Col>
        </Row>
<Row gutter={12} justify="center">
  {weathers.map((item)=>(
    item.id.toString() === citykey.toString() && moment(item.dt,dateFormat)>=d.dt_begin && moment(item.dt, dateFormat) < d.dt_end &&
    <Col key={item.id.toString()+item.dt}>
      <Row justify="center">
        {moment(item.dt,dateFormat).format('Do MMMM')}
      </Row>
      <Row justify="center">
        <img src={item.type} alt=""/>
      </Row>
      <Row justify="center">
        {item.high>0&&'+'}{item.high}
      </Row>
      <Row justify="center">
        {item.high>0&&'+'}{item.low}
      </Row>
    </Col>
  ))}
</Row>
<Row >
  <Col span={24}>
  {citykey !== 0 &&
    <Table 
      columns={columns} 
      dataSource={data()}
      pagination={false}
    />
  }
  </Col>
</Row>
    </div>
  );
}

export default App;
